/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import globalMixins from "@/mixins/globalMixins";

export default {
	name: 'fieldsMixins',
	
	mixins: [globalMixins],
	
	data() {
		return {
			fieldsAcl: {
				resources: {
					create: {},
					update: {}
				},
				database: {
					update: {}
				}
			}
		}
	},
	
	components: {},
	
	computed: {},
	
	methods: {
		
		resourceCreateAccess() {
			this.fieldsAcl.resources.create = {
				config: true,
				category: true,
				locked: true,
			};
			return this.fieldsAcl.resources.create;
		},
		
		resourceUpdateAccess() {
			let route = '/environments/:env/data-clusters/:id';
			let method = 'patch';
			
			this.fieldsAcl.resources.update = {
				config: this.canAccessField({
					route: route,
					method: method,
					field: 'config'
				}),
				category: this.canAccessField({
					route: route,
					method: method,
					field: 'category'
				}),
				locked: this.canAccessField({
					route: route,
					method: method,
					field: 'locked'
				}),
			};
			
			return this.fieldsAcl.resources.update;
		},
		
		databaseUpdateAccess() {
			// let route = '/environments/:env/databases/:name';
			// let method = 'patch';
			
			this.fieldsAcl.database.update = {};
			return this.fieldsAcl.database.update;
		},
		
		databaseCreateAccess() {
			this.fieldsAcl.database.create = {
				cluster: true,
			};
			
			return this.fieldsAcl.database.create;
		}
	}
}
